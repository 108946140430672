<template>
  <v-card class="card mx-auto" :max-width="maxWidth" outlined :min-height="minHeight" :height="height" @click="$emit('click')">
    <v-card-text class="vertical-center text-center white--text" :class="textSize">
      <v-icon color="white">{{ icon }}</v-icon>
      {{ title }}
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiPlus } from '@mdi/js'

export default {
  components: {},
  props: {
    title: {
      type: String,
      default: 'Add',
    },
    maxWidth: {
      type: String,
      default: '344',
    },
    height: {
      type: String,
      default: '100%',
    },
    minHeight: {
      type: String,
      default: '100',
    },
    textSize: {
      type: String,
      default: 'text-h4',
    },
    icon: {
      default: mdiPlus,
    },
  },
  setup() {
    return {}
  },
}
</script>

<style scoped lang="scss">
.card {
  position: relative;
  cursor: pointer;
}
.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
</style>
